import logo from './logo.svg';
import './App.css';

import Hero from "./components/Hero";

function App() {
  return (
      <Hero></Hero>
  );
}

export default App;
